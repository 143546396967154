import GymRatingDeadLiftRepository from '@/shared/http/repositories/socialProject/gymRating-deadLift'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading } from '@/shared/utils/UIHelper'
import VueHighcharts from 'vue2-highcharts'

export default {
  components: {
      VueHighcharts
  },

  props: {
    items: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    literature: null,
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Rendimento da Turma'
      },
      subtitle: {
        text: 'Média de Levantamento Terra'
      },
      xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                color: '#0d75a3',
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      },
      yAxis: {
        min: 0,
        title: {
            text: 'Médias'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: 'Média dê: <b>{point.y:.1f} (Levantamento Terra)</b>'
      },
      series: [{
        name: 'Médias',
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif',
            }
        }
      }]
    }
  }),

  watch: {
    items: {
      handler: function (value) {
        if (value.id) {
          this.loadChargeData(value.id)
        }
      },
      immediate: true
    }
  },

  methods: {
    loadChargeData(id) {
      loading.push()
      GymRatingDeadLiftRepository.GetGymRatingDeadLiftByGymRatingId(id)
      .then(response => {

        if(!IsSuccessCode){
          return Promise.reject()
        }

        const student = response.data.data

        let studentMasculineName = []
        let studentMasculine = []
        let students = []
        let orderDescByDeadLift = []
        let studentAverage = []
        let classAverage = 0
        
        if (student.length != 0) {
          if (this.items.category == 'JUNIOR E SENIOR') {
            studentMasculine = student.filter(sn => sn.deadLift != null).filter(sn => sn.studentGender == 'Masculino')
            students = studentMasculine

            this.literature = this.items.masculineDeadliftLiterature * 100
          }
          
          if (this.items.category == 'JUVENIL I' || this.items.category == 'JUVENIL II') {
            students = student.filter(sn => sn.deadLift != null)

            this.literature = this.items.juvenileDeadliftLiterature * 100
          }

          orderDescByDeadLift = students.sort(function(a,b) {return b.deadLift - a.deadLift;})
          studentMasculineName = orderDescByDeadLift.map(sn => sn.studentName)
          studentAverage = orderDescByDeadLift.map(sn => sn.deadLift)
          classAverage = studentAverage.reduce((value1, value2) => value1 + value2, 0) / studentAverage.length
          this.$emit('classAverage', Number(parseFloat(classAverage).toFixed(1)))
          
          studentMasculineName.push('MÉDIA DA TURMA')
          studentAverage.push(classAverage)

          studentMasculineName.push('LITERATURA')
          studentAverage.push(this.literature)
        }

        setTimeout(() => {
          this.$refs.lineCharts.chart.update({
            xAxis: {
              categories: studentMasculineName,
              text: null
            },
            series: [{
              name: 'Média',
              data: studentAverage
            }],
            title: {
              text: 'Rendimento da Turma',
              align: 'center'
            },
            subtitle: {
              text: 'Média de Levantamento Terra',
              align: 'center'
            }
          })

          loading.pop()
        }, 1300)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico de rendimentos da turma: \n ${e}`)
      })
    }
  }
}