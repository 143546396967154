import GymRatingJumpRepository from '@/shared/http/repositories/socialProject/gymRating-jump'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading } from '@/shared/utils/UIHelper'
import VueHighcharts from 'vue2-highcharts'

export default {
  components: {
      VueHighcharts
  },

  props: {
    items: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    literature: null,
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Rendimento da Turma'
      },
      subtitle: {
        text: 'Média de Saltos'
      },
      xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                color: '#0d75a3',
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      },
      yAxis: {
        min: 0,
        title: {
            text: 'Médias em Metros'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: 'Média dê: <b>{point.y:.1f} (Salto) metros</b>'
      },
      series: [{
        name: 'Médias',
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif',
            }
        }
      }]
    }
  }),

  watch: {
    items: {
      handler: function (value) {
        if (value.id) {
          this.loadChargeData(value.id)
        }
      },
      immediate: true
    }
  },

  methods: {
    loadChargeData(id) {
      loading.push()
      GymRatingJumpRepository.GetGymRatingJumpByGymRatingId(id)
      .then(response => {

        if(!IsSuccessCode){
          return Promise.reject()
        }

        const student = response.data.data

        let studentName = []
        let students = []
        let orderDescByJump = []
        let studentAverage = []
        let classAverage = 0
        
        if (student.length != 0) {          
					students = student.filter(sn => sn.jump != null)

					this.literature = this.items.jumpLiterature

          orderDescByJump = students.sort(function(a,b) {return b.jump - a.jump;})
          studentName = orderDescByJump.map(sn => sn.studentName)
          studentAverage = orderDescByJump.map(sn => sn.jump)
          classAverage = studentAverage.reduce((value1, value2) => value1 + value2, 0) / studentAverage.length
          this.$emit('classAverage', Number(parseFloat(classAverage).toFixed(1)))
          
          studentName.push('MÉDIA DA TURMA')
          studentAverage.push(classAverage)

          studentName.push('LITERATURA')
          studentAverage.push(this.literature)
        }

        setTimeout(() => {
          this.$refs.lineCharts.chart.update({
            xAxis: {
              categories: studentName,
              text: null
            },
            series: [{
              name: 'Média',
              data: studentAverage
            }],
            title: {
              text: 'Rendimento da Turma',
              align: 'center'
            },
            subtitle: {
              text: 'Média de Salto',
              align: 'center'
            }
          })

          loading.pop()
        }, 1300)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico de rendimentos da turma: \n ${e}`)
      })
    }
  }
}