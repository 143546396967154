import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'gymratingbsquat'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
})

const GetById = (id, pagination) => SocialProjectManager.get(`${resourceName}/${id}`, { 
  params: merge(pagination) 
})

const GetGymRatingBsquatByGymRatingId = (gymratingId, pagination) => SocialProjectManager.get(`${resourceName}/gymrating/${gymratingId}`, { 
  params: merge(pagination) 
})

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const ExportExcelJuvAndSeniorMasculine = (id, classAverage, lit, category, className) => SocialProjectManager.get(`${resourceName}/download-excel-bsquat/${id}/avg/${classAverage}/literature/${lit}/category/${category}`, { 
  params: {className}
})

const ExportExcelSeniorFeminine = (id, classAverage, lit, category, className) => SocialProjectManager.get(`${resourceName}/download-excel-bsquat/feminine/${id}/avg/${classAverage}/literature/${lit}/category/${category}`, { 
  params: {className}
})

const GymRatingBsquatRepository = {
  GetById,
  GetAll, 
  GetGymRatingBsquatByGymRatingId,
  Update,
  Delete,
  ExportExcelJuvAndSeniorMasculine,
  ExportExcelSeniorFeminine
}

export default GymRatingBsquatRepository